import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import Homepage from './Pages/Homepage';
import Shop from './Pages/Shop';
import DungeonParties from './Pages/DungeonParties';
import DungeonRental from './Pages/DungeonRental';
import Payment from './Components/Payment';
import AgeVerification from './Components/AgeVerification';
import MainComponent from './Pages/MainComponent';
import MainComponent1 from './Pages/MainComponent1';

const App = () => {
  return (
    <Router>
      <div className="App">
        <AgeVerification />
        <Header />
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/dungeon-rental" element={<DungeonRental />} />
          <Route path="/dungeon-parties" element={<DungeonParties />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/get-owned" element={<MainComponent />} />
          <Route path="/maincomponent1" element={<MainComponent1 />} /> {/* New route for MainComponent1 */}
        </Routes>
      </div>
    </Router> 
  );
}

export default App;
