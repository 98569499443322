import React, { useState } from 'react';

const initialReviews = [
  { initial: 'M', date: '10 April 2024', name: 'Moose', text: 'Amazing, Erotic, Fun, these are just a few words to describe the experience.', rating: 5 },
  { initial: 'C', date: '11 March 2024', name: 'Chally', text: "The best erotic BDSM party I’ve been to in ages! The atmosphere was electric, and the energy never dipped. Can’t wait for the next one!", rating: 5 },
  { initial: 'B', date: '11 Jan 2024', name: 'Brian', text: "An unforgettable night! Great music, amazing people, and top-notch organization. Everything was perfect!", rating: 5 },
  { initial: 'M', date: '12 Jan 2024', name: 'Mary J', text: "Hands down, the party of the week! From the incredible decorations to the non-stop dancing, it was epic from start to finish.", rating: 5 },
  { initial: 'M', date: '15 March 2024', name: 'Matha', text: "The hosts went all out to create a vibe like no other. It was a blast, and I couldn’t stop smiling all night!", rating: 5 },
  { initial: 'A', date: '15 May 2024', name: 'Angela J', text: "Perfect in every way! Fantastic folks, killer playlist, and the ambiance was just right. Can’t wait to attend another week!", rating: 5 },
  { initial: 'M', date: '15 June 2024', name: 'Mandy Moe', text: "Everyone was talking about this party, and it lived up to the hype. I had a fantastic time, and the memories will last a lifetime.", rating: 5 },
  { initial: 'T', date: '15 Feb 2024', name: 'Theody', text: "A party that exceeded all expectations! Great mix of people, awesome food, and the music kept us on the dance floor.", rating: 5 },
  { initial: 'S', date: '15 May 2024', name: 'Stanley', text: "Everything about this party was next level. From the lights to the laughter, it was pure fun!", rating: 5 },
  { initial: 'B', date: '05 Oct 2024', name: 'Bool M', text: "An absolute banger of a night! I haven’t had this much fun in a long time. Already looking forward to attend next week!", rating: 5 },
  { initial: 'D', date: '23 July 2024', name: 'Danny L.', text: "Such good vibes all night long! The crowd was friendly, the drinks were on point, and the music was perfection!", rating: 5 },
  { initial: 'G', date: '23 Jul 2024', name: 'George L', text: "It was an awesome party with fantastic energy. Perfect mix of people, great venue, and music that kept everyone moving.", rating: 5 },
  { initial: 'S', date: '25 Jul 2024', name: 'Seraphina H', text: "A party for the books! The planning and attention to detail were evident. I didn’t want it to end!", rating: 5 },
  { initial: 'M', date: '25 Jul 2024', name: 'Mariach C', text: "The decorations, the DJ, and the whole setup made it a night to remember. It was simply perfect!", rating: 5 },
  { initial: 'S', date: '26 Jul 2024', name: 'Sandra K', text: "Amazing night! Every single detail was thought out. I’m still buzzing from all the fun!", rating: 5 },
  { initial: 'W', date: '26 Jul 2024', name: 'William M', text: "The perfect blend of fun and elegance! Great food, amazing drinks, and a vibe that kept us dancing!", rating: 5 },
  { initial: 'D', date: '28 Jul 2024', name: 'Duke D', text: "A party that set the bar high! It was non-stop excitement with surprises around every corner.", rating: 5 },
  { initial: 'M', date: '30 Jul 2024', name: 'Macathy L', text: "Top-tier event! Every aspect was on point. Can’t remember the last time I had this much fun. Well done!", rating: 5 }
];

const ReviewSection2 = () => {
  const [reviews, setReviews] = useState(initialReviews);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [newReview, setNewReview] = useState({ name: '', date: '', text: '', rating: 5 });

  const reviewsPerPage = 4;
  const startIndex = currentPage * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const visibleReviews = reviews.slice(startIndex, endIndex);

  const handleNext = () => {
    if (endIndex < reviews.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAddReview = () => {
    if (newReview.name && newReview.date && newReview.text) {
      setReviews([{ initial: newReview.name.charAt(0).toUpperCase(), ...newReview }, ...reviews]);
      setNewReview({ name: '', date: '', text: '', rating: 5 });
      setIsAddingReview(false);
      setCurrentPage(0); // Reset to the first page to show the latest review
    }
  };

  return (
    <div className="reviews-section">
      <div className="reviews-header">
        <h3>Reviews</h3>
        <p>4.9 stars based on {reviews.length} reviews</p>
        <button onClick={() => setIsAddingReview(!isAddingReview)} className="add-review-button">
          {isAddingReview ? 'Cancel' : 'Add your review'}
        </button>
      </div>
      {isAddingReview && (
        <div className="review-form">
          <input
            type="text"
            placeholder="Your name"
            value={newReview.name}
            onChange={(e) => setNewReview({ ...newReview, name: e.target.value })}
          />
          <input
            type="date"
            value={newReview.date}
            onChange={(e) => setNewReview({ ...newReview, date: e.target.value })}
          />
          <textarea
            placeholder="Write your review here..."
            value={newReview.text}
            onChange={(e) => setNewReview({ ...newReview, text: e.target.value })}
          />
          <select
            value={newReview.rating}
            onChange={(e) => setNewReview({ ...newReview, rating: parseInt(e.target.value) })}
          >
            {[5, 4, 3, 2, 1].map((star) => (
              <option key={star} value={star}>
                {star} Star{star > 1 && 's'}
              </option>
            ))}
          </select>
          <button onClick={handleAddReview}>Submit Review</button>
        </div>
      )}
      <div className="reviews-list">
        {visibleReviews.map((review, index) => (
          <div key={index} className="review-item">
            <div className="review-initial">{review.initial}</div>
            <div className="review-content">
              <p className="review-date">{review.date}</p>
              <p className="review-name">{review.name}</p>
              <p className="review-text">{review.text}</p>
              <p className="review-rating">{"★★★★★".slice(0, review.rating)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="navigation-buttons">
        {currentPage > 0 && (
          <button onClick={handleBack} className="back-button">
            Back
          </button>
        )}
        {endIndex < reviews.length && (
          <button onClick={handleNext} className="next-button">
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default ReviewSection2;
