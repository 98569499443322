import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DungeonRental.css';
import ReviewSection from '../Components/ReviewSection';
import dungeonrental1 from "../Assets/dungeonrental1.webp";
import dungeonrental2 from "../Assets/dungeonrental2.webp";
import dungeonrental3 from "../Assets/dungeonrental3.webp";
import dungeonrental4 from "../Assets/dungeonrental4.webp";
import dungeonrental5 from "../Assets/dungeonrental5.webp";
import dungeonrental6 from "../Assets/dungeonrental6.webp";
import dungeonrental7 from "../Assets/dungeonrental7.webp";
import dungeonrental9 from "../Assets/dungeonrental9.webp";
import dungeonrental10 from "../Assets/dungeonrental10.webp";

const DominaDungeonRental = () => {
  const [mainImage, setMainImage] = useState(dungeonrental1);
  const [showPriceList, setShowPriceList] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const navigate = useNavigate();

  const thumbnails = [
    dungeonrental1, dungeonrental2, dungeonrental3, dungeonrental4,
    dungeonrental5, dungeonrental6, dungeonrental7,
    dungeonrental9, dungeonrental10,
  ];

  const handleRentClick = () => {
    setShowPriceList(!showPriceList);
  };

  const handleProceedToPayment = () => {
    if (selectedPrice) {
      navigate('/payment', { state: { total: selectedPrice } });
    }
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="image-gallery">
          <div className="main-image">
            <img src={mainImage} alt="Main view of dungeon" />
          </div>
          <div className="thumbnail-slider">
            {thumbnails.map((thumbnail, index) => (
              <img
                key={index}
                src={thumbnail}
                alt={`View ${index + 1}`}
                onClick={() => setMainImage(thumbnail)}
                className={`thumbnail ${mainImage === thumbnail ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className="details-sidebar">
          <h2>Secret Haluma Dungeon</h2>
          <p>★★★★☆</p>
          <button onClick={handleRentClick} className="rent-button">Click to Rent</button>
          {showPriceList && (
            <div className="price-list">
               
               <label>
                <input
                  type="radio"
                  value={400}
                  checked={selectedPrice === 400}
                  onChange={() => setSelectedPrice(400)}
                />
                Hourly (4 Hours)- $400
              </label>
              <label>
                <input
                  type="radio"
                  value={1000}
                  checked={selectedPrice === 1000}
                  onChange={() => setSelectedPrice(1000)}
                />
                Daily - $1000
              </label>
              <label>
                <input
                  type="radio"
                  value={2000}
                  checked={selectedPrice === 2000}
                  onChange={() => setSelectedPrice(2000)}
                />
                Weekend (Friday to Sunday) - $2000
              </label>
              <label>
                <input
                  type="radio"
                  value={5000}
                  checked={selectedPrice === 5000}
                  onChange={() => setSelectedPrice(5000)}
                />
                Weekly (7 days) - $5000
              </label>
            </div>
          )}
          {showPriceList && (
            <button onClick={handleProceedToPayment} className="proceed-button">
              Proceed to Payment
            </button>
          )}
          <section className="overview">
            <h3>Overview</h3>
            <p>
              This is a private BDSM dungeon and its exact location is not made public, however the state of its location here in the US is known by registered Members (Mistresses / Masters), its exact address and location will be revealed after Payments are done.
            </p>
          </section>
          <section className="additional-info">
            <h4>Details</h4>
            <p>This rental can be booked by weekly, weekends, daily or hourly. There is a 4-hour minimum for hourly rentals.</p>
            <ul>
              <li>BDSM gear (bondage, impact, medical play, etc.)</li>
              <li>Hard-points for rope suspension</li>
              <li>Cages and furniture</li>
              <li>Fashion apparel rental available</li>
              <li>Photo backdrop and studio lighting</li>
              <li>Full kitchen and bathroom on-site</li>
              <li>Private entrance</li>
              <li>Secure Wifi and Bluetooth sound system</li>
            </ul>
          </section>
        </div>
      </div>
      <ReviewSection/>
    </div>
  );
};

export default DominaDungeonRental;
