import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DungeonRental.css';
import ReviewSection2 from '../Components/ReviewSection2';
import dungeonparty1 from "../Assets/dungeonparty1.jpg";
import dungeonparty2 from "../Assets/dungeonparty2.jpg";
import dungeonparty3 from "../Assets/dungeonparty3.jpg";
import dungeonparty4 from "../Assets/dungeonparty4.jpg";
import dungeonparty6 from "../Assets/dungeonparty6.jpg";
import dungeonparty7 from "../Assets/dungeonparty7.jpg";
import dungeonparty8 from "../Assets/dungeonparty8.jpg";
import dungeonparty9 from "../Assets/dungeonparty9.jpg";
import dungeonparty10 from "../Assets/dungeonparty10.png";
import dungeonparty11 from "../Assets/dungeonparty11.webp";
import dungeonparty12 from "../Assets/dungeonparty12.jpg";
import dungeonparty13 from "../Assets/dungeonparty13.webp";
import dungeonparty14 from "../Assets/dungeonparty14.jpeg";



const DungeonParties = () => {
 
  const [mainImage, setMainImage] = useState(dungeonparty10);
  const [showPriceList, setShowPriceList] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const navigate = useNavigate();

  const thumbnails = [
    dungeonparty1, dungeonparty2,dungeonparty3, dungeonparty4,
   dungeonparty6, dungeonparty7,dungeonparty8,
    dungeonparty9, dungeonparty10, dungeonparty11, dungeonparty12, dungeonparty13, dungeonparty14
  ];

  const handleRentClick = () => {
    setShowPriceList(!showPriceList);
  };

  const handleProceedToPayment = () => {
    if (selectedPrice) {
      navigate('/payment', { state: { total: selectedPrice } });
    }
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="image-gallery">
          <div className="main-image">
            <img src={mainImage} alt="Main view of dungeon" />
          </div>
          <div className="thumbnail-slider">
            {thumbnails.map((thumbnail, index) => (
              <img
                key={index}
                src={thumbnail}
                alt={`View ${index + 1}`}
                onClick={() => setMainImage(thumbnail)}
                className={`thumbnail ${mainImage === thumbnail ? 'active' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className="details-sidebar">
          <h2>Secret Weekend Dungeon Party</h2>
          <p>★★★★☆</p>
          <button onClick={handleRentClick} className="rent-button">Click to Attend / Membership</button>
          {showPriceList && (
            <div className="price-list">
              <label>
                <input
                  type="radio"
                  value={500}
                  checked={selectedPrice === 500}
                  onChange={() => setSelectedPrice(500)}
                />
                Silver Tour (1 Month, No accomodation) - $500
              </label>
              <label>
                <input
                  type="radio"
                  value={1000}
                  checked={selectedPrice === 1000}
                  onChange={() => setSelectedPrice(1000)}
                />
                Gold Tour (1 Month Attendance, With accomodation) - $1000
              </label>
              <label>
                <input
                  type="radio"
                  value={1500}
                  checked={selectedPrice === 1500}
                  onChange={() => setSelectedPrice(1500)}
                />
                Silver Premium (3 Months, No Accomodation) - $1500
              </label>
              <label>
                <input
                  type="radio"
                  value={2000}
                  checked={selectedPrice === 2000}
                  onChange={() => setSelectedPrice(2000)}
                />
                Gold Premium (3 Months With Accomodation) - $2000
              </label>
            </div>
          )}
          {showPriceList && (
            <button onClick={handleProceedToPayment} className="proceed-button">
              Proceed to Payment
            </button>
          )}
          <section className="overview">
            <h3>Overview</h3>
            <p>
              Our BDSM Parties holds EVERY WEEKEND. The parties are very erotic and classic and for Members ONLY. Mask are compulsory to conceal identity, Good Music, food and drinks are also made available on demand delivered to those in accomdation or without accomodation.
              In the general room we have kits like whips and spanks and other basic toys limited for use in the general room, while each accomodation is fully equipped for sesseions for your pleasure.
            </p>
          </section>
          <section className="additional-info">
            <h4>Details</h4>
            <h3>General Party Room</h3>
            <ul>
              <li>Music</li>
              <li>Food and Drinks</li>
              <li>Cages and furniture</li>
              <li>Fashion apparel rental available</li>
              <li>Photo backdrop and studio lighting</li>
            </ul>
            <h3>Accomodation</h3>
            <ul>
              <li>BDSM gear (bondage, impact, medical play, etc.)</li>
              <li>Hard-points for rope suspension</li>
              <li>Cages and furniture</li>
              <li>Private entrance</li>
              <li>Secure Wifi and Bluetooth sound system</li>
            </ul>
          </section>
        </div>
      </div>
      <ReviewSection2/>
    </div>
  );
};


export default DungeonParties