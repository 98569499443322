import React from "react";
import "./Header.css"; // Assuming you want to style it separately

const Header = () => {
  return (
    <header className="header">
      <div className="header-overlay">
        <h1>WELCOME TO SUBDOMISLAND</h1>

        <h5 style={{ color: "white" }}>
          ALL ACTIVITIES ON THIS SITE MUST BE DISCREET, FOLLOW THE INSTRUCTIONS
          OF YOUR REFERRER
        </h5>
      </div>
    </header>
  );
};

export default Header;
