import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './MainComponent.css';
import angelfranka from "../Assets/angelfranka.jpg";

const MainComponent = () => {
  const [showModal, setShowModal] = useState(true);
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    age: "",
    sex: "",
    occupation: "",
    location: "",
    maritalStatus: "",
    kids: "",
    fetish: "",
    tributeAmount: "",
    tributeFrequency: "",
  });
  const [showForm, setShowForm] = useState();
  const [showTributeInput, setShowTributeInput] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = () => {
    if (email.toLowerCase() === 'angelfranka589@gmail.com') {
      setShowForm(true);
      setShowModal(false);
    } else if (email.toLowerCase() === 'lauraphillip709@gmail.com') {
      localStorage.setItem('validatedEmail', email.toLowerCase()); // Store email for MainComponent1
      navigate('/maincomponent1');
    } else {
      alert("The Email Address Is Not A Registered Member ");
    }
  };
  

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Ensure tributeFrequency has a value
    const updatedFormData = {
      ...formData,
      tributeFrequency: formData.tributeFrequency || "weekly",
    };

    const SERVICE_ID = "service_zsis0ad"; // Replace with your actual service ID
    const TEMPLATE_ID = "template_ob8ozmc"; // Replace with your actual template ID
    const USER_ID = "UwdhZ8u9MB_-3e0cP"; // Replace with your actual user ID

    emailjs.send(SERVICE_ID, TEMPLATE_ID, updatedFormData, USER_ID)
      .then((response) => {
        console.log("Form Data Submitted:", updatedFormData);
        alert("Your request is under review, we shall contact you via your email address.");
        navigate('/');
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        alert("There was an issue submitting your request. Please try again later.");
      });
  };

  return (
    <div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <h3>REFERRER'S EMAIL ADDRESS</h3>
              <button className="close-button" onClick={() => navigate('/')}>✕</button>
            </div>
            <input 
              type="email" 
              placeholder="Enter referral email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleEmailSubmit}>Submit</button>
          </div>
        </div>
      )}

      {showForm && (
        <div className="form-page">
          <h2>WELCOME TO MISTRESS ANGEL'S SECTION</h2>
          <p>PLEASE FILL THE FORM BELOW</p>
          <img src={angelfranka} alt="Mistress Angel" className="side-image" />
          
          <form onSubmit={handleFormSubmit}>
            {/* Form Fields */}
            <label>Name:</label>
            <input type="text" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
            
 <label>Email:</label>
            <input type="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />

            <label>Age:</label>
            <input type="number" value={formData.age} onChange={(e) => setFormData({ ...formData, age: e.target.value })} required />

            <label>Sex:</label>
            <input type="text" value={formData.sex} onChange={(e) => setFormData({ ...formData, sex: e.target.value })} required />

            <label>Occupation:</label>
            <input type="text" value={formData.occupation} onChange={(e) => setFormData({ ...formData, occupation: e.target.value })} required />

            <label>Location:</label>
            <input type="text" value={formData.location} onChange={(e) => setFormData({ ...formData, location: e.target.value })} required />

            <label>Marital Status:</label>
            <input type="text" value={formData.maritalStatus} onChange={(e) => setFormData({ ...formData, maritalStatus: e.target.value })} required />

            <label>Kids:</label>
            <input type="text" value={formData.kids} onChange={(e) => setFormData({ ...formData, kids: e.target.value })} required />

            <label>Fetish/Desires:</label>
            <textarea value={formData.fetish} onChange={(e) => setFormData({ ...formData, fetish: e.target.value })} required></textarea>

            <label>
              <input type="checkbox" onChange={(e) => setShowTributeInput(e.target.checked)} /> Tribute
            </label>

            {showTributeInput && (
              <>
                <label>Tribute Amount ($):</label>
                <input type="number" value={formData.tributeAmount} onChange={(e) => setFormData({ ...formData, tributeAmount: e.target.value })} />

                <label>Tribute Frequency:</label>
                <select value={formData.tributeFrequency} onChange={(e) => setFormData({ ...formData, tributeFrequency: e.target.value })}>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </>
            )}
            
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MainComponent;