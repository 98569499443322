import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import bitcoinIcon from "../Assets/bitcoin.png";
import cashAppIcon from "../Assets/cashapp.webp";
import venmoIcon from "../Assets/venmo.png";
import zelleIcon from "../Assets/zelle.svg";
import paypalIcon from "../Assets/paypal.png";
import giftcardIcon from "../Assets/giftcard.png";
import qrCodeImage from "../Assets/trustwallet.jpg";

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialTotal = parseFloat(location.state?.total) || 0;
  const fromShop = location.state?.from === "shop";
  
  const deliveryFee = fromShop ? 20 : 0;
  const totalWithFees = initialTotal + deliveryFee;

  const [selectedPayment, setSelectedPayment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isGiftCardModal, setIsGiftCardModal] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", amount: totalWithFees.toFixed(2) });

  const paymentOptions = [
    { name: "Bitcoin", icon: bitcoinIcon },
    { name: "CashApp", icon: cashAppIcon },
    { name: "Venmo", icon: venmoIcon },
    { name: "Zelle", icon: zelleIcon },
    { name: "PayPal", icon: paypalIcon },
    { name: "Gift Card", icon: giftcardIcon },
  ];

  const handlePaymentSelection = (name) => {
    setSelectedPayment(name);
    if (name === "Bitcoin") {
      setIsGiftCardModal(false);
      setShowModal(true);
    } else if (name === "Gift Card") {
      setIsGiftCardModal(true);
      setShowModal(true);
    } else {
      setShowFormModal(true); // Show form for CashApp, Venmo, Zelle, and PayPal
    }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText("bc1qxyyvxjewp8vl5vznxx7za6wdv66j55ew8d4g44");
    alert("Bitcoin wallet address copied!");
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    emailjs.send(
        "service_zsis0ad",  // Your EmailJS service ID
        "template_ob8ozmc", // Your EmailJS template ID
        { 
          name: formData.name,
          email: formData.email,
          selected_payment_method: selectedPayment,  // For the Payment component
          total_amount: `$${totalWithFees.toFixed(2)}`
          // Other variables (age, sex, etc.) are not included here
        },
        "UwdhZ8u9MB_-3e0cP"  // Your EmailJS user ID
      )
      
      
      
      
    .then((response) => {
      console.log("Email sent successfully", response);
      alert(`Check your Email for ${selectedPayment} Tag`);
      setShowFormModal(false);
    })
    .catch((error) => {
      console.error("Failed to send email", error);
      alert("Failed to send email, please try again.");
    });
  };

  return (
    <div style={styles.container}>
      <button onClick={() => navigate(-1)} style={styles.backButton}>
        Back
      </button>
      <h1 style={styles.heading}>Payment Page</h1>
      <p style={styles.totalCost}>Initial Total: ${initialTotal.toFixed(2)}</p>
      {fromShop && (
        <>
          <p style={styles.totalCost}>Delivery Fee: $20</p>
        </>
      )}
      <h2 style={styles.totalCost}>Total Cost: ${totalWithFees.toFixed(2)}</h2>
      
      <h2 style={styles.subHeading}>Select Payment Method</h2>
      <div style={styles.paymentOptions}>
        {paymentOptions.map((option) => (
          <div
            key={option.name}
            onClick={() => handlePaymentSelection(option.name)}
            style={{
              ...styles.paymentOption,
              border: selectedPayment === option.name ? "2px solid #007bff" : "1px solid gray",
            }}
          >
            <img src={option.icon} alt={option.name} style={styles.paymentIcon} />
            <span>{option.name}</span>
          </div>
        ))}
      </div>

      <button 
        onClick={() => alert(`Payment Processed with ${selectedPayment}`)}
        disabled={!selectedPayment}
        style={styles.confirmButton}
      >
        Confirm Payment
      </button>

      {/* Bitcoin & Gift Card Modals */}
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            {isGiftCardModal ? (
              <>
                <h3>FOLLOW THE INSTRUCTION</h3>
                <p>
                  Carefully scratch off the silver panel on the card to reveal the PIN.
                  Then take a clear full picture of both the front and back sides of the card.
                  Take pictures of all receipts of the cards from the purchase (card must be activated).
                  Send all photos taken to <b>subdomisland@gmail.com</b>.
                </p>
                <p>Wait for feedback before disposing the items.</p>
              </>
            ) : (
              <>
                <h3>Pay to this Bitcoin Wallet Address</h3>
                <h4>"ScreenShot Me"</h4>
                <img src={qrCodeImage} alt="QR Code" style={styles.qrCodeImage} />
                <p>bc1qxyyvxjewp8vl5vznxx7za6wdv66j55ew8d4g44</p>
                <button onClick={handleCopyAddress} style={styles.copyButton}>Copy Address</button>
              </>
            )}
            <button onClick={() => setShowModal(false)} style={styles.closeButton}>Close</button>
          </div>
        </div>
      )}

      {/* Form Modal for CashApp, Venmo, Zelle, and PayPal */}
      {showFormModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <h3>{selectedPayment} Payment Details</h3>
            <form onSubmit={handleFormSubmit}>
              <input
                type="text"
                placeholder="Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                required
                style={styles.input}
              />
              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
                style={styles.input}
              />
              <input
                type="text"
                placeholder="Amount"
                value={`$${totalWithFees.toFixed(2)}`}
                readOnly
                style={styles.input}
              />
              <button type="submit" style={styles.submitButton}>Submit</button>
            </form>
            <button onClick={() => setShowFormModal(false)} style={styles.closeButton}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
    container: {
      backgroundColor: "#1c1c1e",
      color: "#fff",
      padding: "40px",
      minHeight: "100vh",
      textAlign: "center",
      '@media (max-width: 768px)': {
        padding: "20px",
      },
    },
    backButton: {
      position: "absolute",
      top: "20px",
      left: "20px",
      padding: "10px 20px",
      fontSize: "1em",
      backgroundColor: "#1c1c1e",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      '@media (max-width: 768px)': {
        padding: "8px 16px",
        fontSize: "0.9em",
      },
    },
    heading: {
      fontSize: "2em",
      marginBottom: "20px",
      '@media (max-width: 768px)': {
        fontSize: "1.5em",
      },
    },
    totalCost: {
      fontSize: "1.2em",
      marginBottom: "20px",
      '@media (max-width: 768px)': {
        fontSize: "1em",
      },
    },
    subHeading: {
      fontSize: "1.5em",
      marginBottom: "20px",
      '@media (max-width: 768px)': {
        fontSize: "1.2em",
      },
    },
    paymentOptions: {
      display: "flex",
      gap: "20px",
      flexWrap: "wrap",
      justifyContent: "center",
      marginBottom: "30px",
      '@media (max-width: 768px)': {
        gap: "10px",
      },
    },
    paymentOption: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      padding: "20px",
      borderRadius: "8px",
      backgroundColor: "#333",
      transition: "transform 0.2s",
      '@media (max-width: 768px)': {
        padding: "15px",
      },
    },
    paymentIcon: {
      width: "80px",
      height: "80px",
      marginBottom: "10px",
      '@media (max-width: 768px)': {
        width: "60px",
        height: "60px",
      },
    },
    confirmButton: {
      backgroundColor: "#007bff",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "1em",
      marginTop: "20px",
      '@media (max-width: 768px)': {
        width: "100%",
        padding: "10px",
      },
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: "#fff",
      color: "#000",
      padding: "20px",
      borderRadius: "8px",
      textAlign: "center",
      width: "90%",
      maxWidth: "600px",
      '@media (max-width: 768px)': {
        width: "90%",
        padding: "15px",
      },
    },
    qrCodeImage: {
      width: "300px",
      height: "300px",
      margin: "20px 0",
      '@media (max-width: 768px)': {
        width: "200px",
        height: "200px",
      },
    },
    copyButton: {
      backgroundColor: "#007bff",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      marginBottom: "10px",
      '@media (max-width: 768px)': {
        width: "100%",
        padding: "8px",
      },
    },
    closeButton: {
      backgroundColor: "#ccc",
      color: "#000",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      '@media (max-width: 768px)': {
        width: "100%",
        padding: "8px",
      },
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "4px",
      border: "1px solid #ddd",
      '@media (max-width: 768px)': {
        padding: "8px",
      },
    },
    submitButton: {
      backgroundColor: "#007bff",
      color: "#fff",
      padding: "10px 20px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      width: "100%",
      '@media (max-width: 768px)': {
        padding: "10px",
      },
    },
  };
  

export default Payment;




