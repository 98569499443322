import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav style={styles.navbar}>
      <Link to="/" style={styles.link}>Home</Link>
      <Link to="/shop" style={styles.link}>Shop</Link>
      <Link to="/dungeon-rental" style={styles.link}>Dungeon Rental</Link>
      <Link to="/dungeon-parties" style={styles.link}>Dungeon Parties</Link>
    </nav>
  );
};

const styles = {
  navbar: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '1em',
    backgroundColor: '#333',
    position: 'sticky',    // Use sticky instead of fixed
    top: 0,                // Stick to the top of the viewport
    width: '100%',
    zIndex: 1000,
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '1.2em',
  },
};

export default Navbar;
