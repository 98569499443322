import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import getOwnedImage from '../Assets/getOwned.jpg';
import buyToysImage from '../Assets/buyToys.webp';
import rentDungeonImage from '../Assets/rentDungeon.webp';
import partyImage from '../Assets/party.webp';

const HomePage = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const handleGetOwnedClick = () => {
    navigate('/get-owned'); // Navigate to the MainComponent route
  };

  return (
    <div className="homepage-wrapper">
      <div className="homepage-container">
        <div className="homepage-column" onClick={handleGetOwnedClick}>
          <img src={getOwnedImage} alt="Get Owned" className="homepage-image" />
          <h3>Get Owned</h3>
        </div>
        <div className="homepage-column" onClick={() => navigate('/shop')}>
          <img src={buyToysImage} alt="Buy Toys" className="homepage-image" />
          <h3>Buy Toys</h3>
        </div>
        <div className="homepage-column" onClick={() => navigate('/dungeon-rental')}>
          <img src={rentDungeonImage} alt="Rent A Dungeon" className="homepage-image" />
          <h3>Rent A Dungeon</h3>
        </div>
        <div className="homepage-column" onClick={() => navigate('/dungeon-parties')}>
          <img src={partyImage} alt="Let's Have A Party" className="homepage-image" />
          <h3>Let's Have A Party</h3>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="homepage-footer">
        <p>&copy; {currentYear} SubdomIsland. All rights reserved.</p>
        <p>Contact: subdomisland@gmail.com</p>
        <p>About: This is a private website. Most visitors visiting for the first time are referred by registered members of the SubdomIsland agency international. All activities on this site are private and discreet, including the locations of all our facilities. While most locations are known to our members, some, such as the venue of our parties, are revealed 2 days before the event.</p>
      </footer>
    </div>
  );
};

export default HomePage;
