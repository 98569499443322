import React, { useState } from 'react';
import './ReviewSection.css';

const initialReviews = [
  { initial: 'M', date: '15 Jan 2024', name: 'Marty', text: 'There was too much for only one night... we plan to be back.', rating: 5 },
  { initial: 'C', date: '15 Jan 2024', name: 'Chris', text: "If you're into it, you'll love it. If you're just curious, you'll love it. If you just need a place to stay, well the bed is comfortable too.", rating: 5 },
  { initial: 'B', date: '15 Jan 2024', name: 'Bob', text: "A great place with tons to do! Book it, you'll love it.", rating: 5 },
  { initial: 'M', date: '15 Jan 2024', name: 'Marissa', text: "There were so many more things than pictured! It was awesome. I'd definitely stay again.", rating: 5 },
  { initial: 'M', date: '15 Jan 2024', name: 'Marcus', text: "Me and my fiancee decided book on a short notice and the hosts were more than accommodating! the room was spotless and the check in instructions were crystal clear!", rating: 5 },
  { initial: 'A', date: '15 Jan 2024', name: 'Angela', text: "omfg!! my wife and i stayed here for our anniversary night. it was amazing. we were blown away by the amount of things to do.", rating: 5 },
  { initial: 'M', date: '15 Jan 2024', name: 'Melissa', text: "When can we come back?! As soon as we stepped into the place, it felt magical. We were awestruck by all the accessories at our disposal.", rating: 5 },
  { initial: 'T', date: '15 Jan 2024', name: 'Timmothy', text: "Lol. We were searching for a romantic getaway and found this. Perfect location and completely exceed expectations. ❤", rating: 5 },
  { initial: 'S', date: '15 Jan 2024', name: 'Starr', text: "This was definitely a unique experience!!! I haven't stop talking about it to my friends!", rating: 5 },
  { initial: 'B', date: '05 Oct 2023', name: 'Ben', text: "Hot spot for a spicy date night! There are a lot of toys to play with in this dungeon! We had soooo much fun exploring each other.", rating: 5 },
  { initial: 'D', date: '14 Aug 2023', name: 'Diana L.', text: "Best dungeon I have ever stayed in! Very clean & well stocked. The host is very accommodating!!", rating: 5 },
  { initial: 'S', date: '14 Jul 2023', name: 'Serena L', text: "I have stayed in this studio before.. it is incredible! A versatile space with many delightful BDSM furniture pieces.", rating: 5 }
];

const ReviewSection = () => {
  const [reviews, setReviews] = useState(initialReviews);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [newReview, setNewReview] = useState({ name: '', date: '', text: '', rating: 5 });

  const reviewsPerPage = 4;

  const startIndex = currentPage * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  const visibleReviews = reviews.slice(startIndex, endIndex);

  const handleNext = () => {
    if (endIndex < reviews.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAddReview = () => {
    if (newReview.name && newReview.date && newReview.text) {
      setReviews([{ initial: newReview.name.charAt(0).toUpperCase(), ...newReview }, ...reviews]);
      setNewReview({ name: '', date: '', text: '', rating: 5 });
      setIsAddingReview(false);
      setCurrentPage(0); // Reset to the first page to show the latest review
    }
  };

  return (
    <div className="reviews-section">
      <div className="reviews-header">
        <h3>Reviews</h3>
        <p>4.9 stars based on {reviews.length} reviews</p>
        <button onClick={() => setIsAddingReview(!isAddingReview)} className="add-review-button">
          {isAddingReview ? 'Cancel' : 'Add your review'}
        </button>
      </div>
      {isAddingReview && (
        <div className="review-form">
          <input
            type="text"
            placeholder="Your name"
            value={newReview.name}
            onChange={(e) => setNewReview({ ...newReview, name: e.target.value })}
          />
          <input
            type="date"
            value={newReview.date}
            onChange={(e) => setNewReview({ ...newReview, date: e.target.value })}
          />
          <textarea
            placeholder="Write your review here..."
            value={newReview.text}
            onChange={(e) => setNewReview({ ...newReview, text: e.target.value })}
          />
          <select
            value={newReview.rating}
            onChange={(e) => setNewReview({ ...newReview, rating: parseInt(e.target.value) })}
          >
            {[5, 4, 3, 2, 1].map((star) => (
              <option key={star} value={star}>
                {star} Star{star > 1 && 's'}
              </option>
            ))}
          </select>
          <button onClick={handleAddReview}>Submit Review</button>
        </div>
      )}
      <div className="reviews-list">
        {visibleReviews.map((review, index) => (
          <div key={index} className="review-item">
            <div className="review-initial">{review.initial}</div>
            <div className="review-content">
              <p className="review-date">{review.date}</p>
              <p className="review-name">{review.name}</p>
              <p className="review-text">{review.text}</p>
              <p className="review-rating">{'★★★★★'.slice(0, review.rating)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="navigation-buttons">
        {currentPage > 0 && (
          <button onClick={handleBack} className="back-button">
            Back
          </button>
        )}
        {endIndex < reviews.length && (
          <button onClick={handleNext} className="next-button">
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default ReviewSection;
