import React, { useState, useEffect } from 'react';
import "./AgeVerification.css"

const AgeVerification = () => {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const ageVerified = localStorage.getItem('ageVerified');
    if (ageVerified === 'true') {
      setIsVerified(true);
    }
  }, []);

  const handleVerify = () => {
    localStorage.setItem('ageVerified', 'true');
    setIsVerified(true);
  };

  const handleUnderage = () => {
    window.location.href = 'https://www.google.com';
  };

  if (isVerified) return null;

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-modal">
        <h1><strong>SUBDOMISLAND</strong></h1>
        <p>This site is for consenting adults over 18+ years of age only!</p>
        <p>By clicking "Yes, I am over 18+ years old" you agree the following statements are true:</p>
        <ul>
          <li>I am over 18+ years of age</li>
          <li>I will not allow any minors to access this site or any material found herein</li>
        </ul>
        <div className="age-verification-buttons">
          <button onClick={handleVerify} className="verify-button">Yes, I am over 18+ years of age</button>
          <button onClick={handleUnderage} className="underage-button">No, I am underage</button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;
