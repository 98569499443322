import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Shop.css';
import SetAnalPlugDifferentSizesButtyPlug from "../Assets/SetAnalPlugDifferentSizesButtyPlug.jpg";
import AdultFemaleUnisexBDSMKitAdvancedBondageSex from "../Assets/AdultFemaleUnisexBDSMKitAdvancedBondageSex.jpg";
import AnalPlugPlusVibrator5PcsAnalSexSet from "../Assets/AnalPlugPlusVibrator5PcsAnalSexSet.jpg";
import blowjobmask from "../Assets/blowjobmask.jpg";
import BDSMKitsAdultSexToys7PiecesBondageRestraint from "../Assets/BDSMKitsAdultSexToys7PiecesBondageRestraint.jpg";
import BDSMNeckToWristRestraintsBondageSetBehindBackHandcuffsCollarWithBlindfoldAdjustableBondageSetBed from "../Assets/BDSMNeckToWristRestraintsBondageSetBehindBackHandcuffsCollarWithBlindfoldAdjustableBondageSetBed.jpg";
import BDSMPULeatherSexKitsPackageHandcuffsBondage from "../Assets/BDSMPULeatherSexKitsPackageHandcuffsBondage.jpg";
import BondageBjMouthHold from "../Assets/BondageBjMouthHold.jpg";
import bondagechairwithrope from "../Assets/bondagechairwithrope.jpg";
import brownleatherwhip from "../Assets/brownleatherwhip.jpg";
import Blcakleatherwhip from "../Assets/Blcakleatherwhip.jpg";
import ElectroButtPlug from "../Assets/ElectroButtPlug.webp";
import electroplay from "../Assets/electroplay.webp";
import EroticBondageCoupleSexToy from "../Assets/EroticBondageCoupleSexToy.jpg";
import ForMenVibratingMasturbator from "../Assets/ForMenVibratingMasturbator.jpg";
import HandsAnkleCuffsRestraintsBDSMStraps from "../Assets/HandsAnkleCuffsRestraintsBDSMStraps.jpg";
import leatherspankingbelt from "../Assets/leatherspankingbelt.jpg";
import Legrestraintbond from "../Assets/Legrestraintbond.jpg";
import metalcockhold from "../Assets/metalcockhold.jpg";
import OpenLegBarBondageKitRestraintLegOpenAnkleHandcuffs from "../Assets/OpenLegBarBondageKitRestraintLegOpenAnkleHandcuffs.webp";
import pairmetalbuttplug from "../Assets/pairmetalbuttplug.jpg";
import Restraintfurniture from "../Assets/Restraintfurniture.png";
import SexGamesMaskLady from "../Assets/SexGamesMaskLady.jpg";
import shredleatherwhip from "../Assets/shredleatherwhip.jpg";
import SiliconePenisRingRingAdultSexProductsMaleDelay from "../Assets/SiliconePenisRingRingAdultSexProductsMaleDelay.jpg";
import SpikyPenisSleeveExtenderRealisticPenisToyForMen from "../Assets/SpikyPenisSleeveExtenderRealisticPenisToyForMen.jpg";
import StainlessSteelChastityCage from "../Assets/StainlessSteelChastityCage.webp";
import UrethralPlugUrethraSoundsBEADSDilator from "../Assets/UrethralPlugUrethraSoundsBEADSDilator.webp";
import whiterope from "../Assets/whiterope.jpg"
import WomenMenStrapOnWithBeltDildoVibrator from "../Assets/WomenMenStrapOnWithBeltDildoVibrator.jpg";
import woodenchastitychair from "../Assets/woodenchastitychair.jpg";
import woodenspank from "../Assets/woodenspank.jpg";
import WristsAnkleCuffsUnderBedBDSMBondageRestraintErotic from "../Assets/WristsAnkleCuffsUnderBedBDSMBondageRestraintErotic.jpg";
import metalkeylockcockhold from "../Assets/metalkeylockcockhold.jpg";
import chastitychairwithvibrator from "../Assets/chastitychairwithvibrator.jpg";
import FlirtyLegStrap from "../Assets/FlirtyLegStrap.jpg";
import BondageSetHandcuffsAnkleCuffRestraints from "../Assets/BondageSetHandcuffsAnkleCuffRestraints.jpg";
import KitsBondageHandcuffsWhipGagNippleClamps from "../Assets/KitsBondageHandcuffsWhipGagNippleClamps.jpg";
import BondageForMouthAndHandsHold from "../Assets/BondageForMouthAndHandsHold.jpg";
import ExoticAccessoriesHandcuffAdultSexCollar from "../Assets/ExoticAccessoriesHandcuffAdultSexCollar.jpg";
import malevibrator from "../Assets/malevibrator.jpg";
import SpeculumAnalSexPlugDilatorHollowExtender from "../Assets/SpeculumAnalSexPlugDilatorHollowExtender.webp";
import VinylBedSheet from "../Assets/VinylBedSheet.webp";
import TetherBallStretcherHumblerCBT from "../Assets/TetherBallStretcherHumblerCBT.webp";
import LuxuryCollarwithLeash from "../Assets/LuxuryCollarwithLeash.webp";
import SpreaderBarBondageRestraintKitHandcuffsAnkleCuffs from "../Assets/SpreaderBarBondageRestraintKitHandcuffsAnkleCuffs.webp";
import restraintHandcuffsCockRingwithchains from "../Assets/restraintHandcuffsCockRingwithchains.webp";
import SexSwingforAdultSexToy from "../Assets/SexSwingforAdultSexToy.webp";
import InflatableSexSofaChairBed from "../Assets/InflatableSexSofaChairBed.webp";
import LeatherFetishBDSMChastityLockSet from "../Assets/LeatherFetishBDSMChastityLockSet.webp";
import DoggieStyleWaistStraps from "../Assets/DoggieStyleWaistStraps.webp";
import BedRestraintHandcuffsAnkleCuffs from "../Assets/BedRestraintHandcuffsAnkleCuffs.webp";
import SexChairwithSplitLegRestraintandHandcuffs  from "../Assets/SexChairwithSplitLegRestraintandHandcuffs.webp";
import VibratingPenisCockSexRing from "../Assets/VibratingPenisCockSexRing.webp";
import CockRingPenisErectionEnhancer from "../Assets/CockRingPenisErectionEnhancer.webp";
import MouthDildoPenisToy from "../Assets/MouthDildoPenisToy.webp";


const Shop = () => {
  // Initialize cart with data from local storage or an empty array
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Save cart to local storage whenever it changes
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  

  const items = [
    { id: 1, name: "3 Pieces 3 Set Anal Plug", price: 25.5, image: SetAnalPlugDifferentSizesButtyPlug },
    { id: 2, name: "Advanced Unisex Bondage", price: 29.3, image: AdultFemaleUnisexBDSMKitAdvancedBondageSex },
    { id: 3, name: "Flirty Leg Strap (Black)", price: 20.0, image: FlirtyLegStrap },
    { id: 4, name: "Anal Plug & Vibrator", price: 19.5, image: AnalPlugPlusVibrator5PcsAnalSexSet },
    { id: 5, name: "Handcuff And Collar", price: 21.5, image: ExoticAccessoriesHandcuffAdultSexCollar },
    { id: 6, name: "7 Pieces Bondage Restraint", price: 70.4, image:BDSMKitsAdultSexToys7PiecesBondageRestraint},
    { id: 7, name: "Neck To Wrist Restraints Bondage With BlindFold", price: 45.8, image:BDSMNeckToWristRestraintsBondageSetBehindBackHandcuffsCollarWithBlindfoldAdjustableBondageSetBed},
    { id: 8, name: "Leather Sex Kits Bondage Package", price: 49.5, image: BDSMPULeatherSexKitsPackageHandcuffsBondage},
    { id: 9, name: "Bed Bondage Set Handcuffs AnkleCuff", price: 40.5, image:BondageSetHandcuffsAnkleCuffRestraints },
    { id: 10, name: "Handcuffs Whip Gag Nipple Clamps Kits", price: 45.0, image: KitsBondageHandcuffsWhipGagNippleClamps },
    { id: 11, name: "Bj Mouth Hold", price: 20.0, image:BondageBjMouthHold },
    { id: 12, name: "Bondage For Mouth And Hands Hold", price: 35.5, image: BondageForMouthAndHandsHold },
    { id: 13, name: "Erotic Bondage Couple Sex Toy Kits", price: 85.5, image: EroticBondageCoupleSexToy },
    { id: 14, name: "Men Vibrating Masturbator", price: 55.3, image: malevibrator },
    { id: 15, name: "Hands Ankle Cuffs Restraints Straps", price: 28.3, image: HandsAnkleCuffsRestraintsBDSMStraps},
    { id: 16, name: "Leg restraint bond", price: 37.5,image: Legrestraintbond},
    { id: 17, name: "Restraint furniture", price: 155.2, image:Restraintfurniture },
    { id: 18, name: "Leather Sex Mask", price: 25.4, image: SexGamesMaskLady },
    { id: 19, name: "Male Delay Silicone Penis Ring", price: 15.0, image: SiliconePenisRingRingAdultSexProductsMaleDelay },
    { id: 20, name: "Spiky Penis Sleeve Extender", price: 20.5, image: SpikyPenisSleeveExtenderRealisticPenisToyForMen},
    { id: 21, name: "Strap On With Vibrator", price: 55.3, image: WomenMenStrapOnWithBeltDildoVibrator},
    { id: 22, name: "Vibrating Masturbator", price: 35.0, image: ForMenVibratingMasturbator },
    { id: 23, name: "Hands And Legs Bed Restraint", price: 70.6, image: WristsAnkleCuffsUnderBedBDSMBondageRestraintErotic },
    { id: 24, name: "Leather Spanking Belt", price: 15.5, image: leatherspankingbelt },
    { id: 25, name: "Black Leather Whip", price: 18.0, image:Blcakleatherwhip },
    { id: 26, name: "Bondage Chair With Rope", price: 65.5, image:bondagechairwithrope },
    { id: 27, name: "Metal Cockhold", price: 16.0, image: metalcockhold },
    { id: 28, name: "Wooden Spank", price: 15.0, image:woodenspank },
    { id: 29, name: "Shred Leather Whip ", price: 18.5, image: shredleatherwhip },
    { id: 30, name: "White Rope", price: 7.0, image: whiterope },
    { id: 31, name: "Electric Chastity Chair With Dildo", price: 200.0, image: chastitychairwithvibrator },
    { id: 32, name: "Metal Butt Plug (Pair)", price: 23.5, image: pairmetalbuttplug },
    { id: 33, name: "Wooden Chastity Chair", price: 175.5, image:woodenchastitychair},
    { id: 34, name: "Metal Keylock Cockhold", price: 20.0, image: metalkeylockcockhold},
    { id: 35, name: "Blowjob Mask", price: 25.5, image: blowjobmask},
    { id: 36, name: "Brown Leather Whip", price: 21.0, image: brownleatherwhip },
    { id: 37, name: "Electroplay", price: 245.7, image: electroplay},
    { id: 38, name: "Electro Butt Plug", price: 85.5, image: ElectroButtPlug },
    { id: 39, name: "Stainless Steel Chastity Cage", price: 7.0, image:StainlessSteelChastityCage },
    { id: 40, name: "Urethral Plug Urethra Sounds BEADS Dilator", price: 12.0, image: UrethralPlugUrethraSoundsBEADSDilator },
    { id: 41, name: "Open Leg Bar Bondage Kit Restraint Leg Open Ankle Handcuffs", price: 42.4, image: OpenLegBarBondageKitRestraintLegOpenAnkleHandcuffs },
    { id: 42, name: "Speculum Anal Sex Plug Dilator Hollow Extender", price: 12.4, image: SpeculumAnalSexPlugDilatorHollowExtender },
    { id: 43, name: "Vinyl Bed Sheet", price: 26.4, image:VinylBedSheet},
    { id: 44, name: "Tether Ball Stretcher Humbler CBT", price: 22.7, image: TetherBallStretcherHumblerCBT },
    { id: 45, name: "Luxury Collar with Leash", price: 17.9, image: LuxuryCollarwithLeash },
    { id: 46, name: "Spreader Bar Bondage Restraint Kit Handcuffs Ankle Cuffs", price: 50.4, image: SpreaderBarBondageRestraintKitHandcuffsAnkleCuffs },
    { id: 47, name: "restraint Handcuffs & Cock Ring with chains", price: 42.8, image: restraintHandcuffsCockRingwithchains },
    { id: 48, name: "Sex Swing for Adult Sex Toy", price: 87.5, image: SexSwingforAdultSexToy },
    { id: 49, name: "Inflatable Sex Sofa Chair Bed", price: 252.1, image: InflatableSexSofaChairBed },
    { id: 50, name: "Leather Fetish BDSM Chastity Lock Set", price: 21.4, image: LeatherFetishBDSMChastityLockSet },
    { id: 51, name: "Doggie Style Waist Straps", price: 17.0, image: DoggieStyleWaistStraps },
    { id: 52, name: "Bed Restraint Handcuffs Ankle Cuffs", price: 162.5, image: BedRestraintHandcuffsAnkleCuffs },
    { id: 53, name: "Sex Chair with Split Leg Restraint and Handcuffs", price: 142.4, image: SexChairwithSplitLegRestraintandHandcuffs },
    { id: 54, name: "Vibrating Penis Cock Sex Ring", price: 23.3, image: VibratingPenisCockSexRing},
    { id: 55, name: "Cock Ring Penis Erection Enhancer", price: 18.7, image: CockRingPenisErectionEnhancer},
    { id: 56, name: "Mouth-Dildo-Penis-Toy", price: 20.4, image: MouthDildoPenisToy },
  ];


  const addToCart = (item) => {
    setCart([...cart, item]);
  };

  const calculateTotal = () => {
    return cart.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  };

  const proceedToPayment = () => {
    const total = calculateTotal();
    navigate("/payment", { state: { total, from: "shop" } }); // Pass the 'from' identifier
  };
  

  // Clear cart function
  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart"); // Remove cart data from local storage
  };

  return (
    <div className="all">
      <h1 style={{ color: "#923232" }}>Kits Store</h1>
      <h3 style={{ color: "GrayText" }}>
        While Shopping for toys as instructed, please note all items will be packaged in disguised for privacy purposes but all your items will be completely delivered
      </h3>
      <div className="products-grid">
        {items.map((item) => (
          <div key={item.id} className="product-card">
            <img src={item.image} alt={item.name} className="product-image" />
            <span>{item.name}</span> <br />
            <span>${item.price}</span>
            <button onClick={() => addToCart(item)} className="add-to-cart-button">
              Add to Cart
            </button>
          </div>
        ))}
      </div>
      <div className="ctp">
        <h2>Cart</h2>
        <ul>
          {cart.map((item, index) => (
            <li key={index}>
              {item.name} - ${item.price}
            </li>
          ))}
        </ul>
        <h3>Total: ${calculateTotal()}</h3>
        <button onClick={proceedToPayment} className="proceed-button">
          Proceed to Payment
        </button>
        {/* Clear Cart Button */}
        <button onClick={clearCart} className="clear-cart-button">
          Clear Cart
        </button>
      </div>
    </div>
  );
};

export default Shop;
